import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'

import { MainLayout, PortfolioCard, PaginationHelper } from '../components/common'
import { MetaData } from '../components/common/meta'
import { Layout, Row, Col, Divider, Typography, Tag } from 'antd'
const { Content } = Layout
const { Title, Text, Paragraph } = Typography
import { Parallax } from 'rc-scroll-anim'
import Prism from 'prismjs'
import Wave from "../images/icons/wave.svg";
/**
* Portfolio posts page (/portfolio)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/
const Portfolio = ({ data, location, pageContext }) => {
    const posts = data.portfolio.nodes

    useEffect(() => {
        Prism.highlightAll()
    })

    const page = {
        page: {
            title: `Portfolio`,
            meta_title: ``,
            meta_description: `Portfolio of work. A sample of the work and projects Joe Czubiak has done.`,
            name: ``,
            feature_image: ``,
            description: `Portfolio of work. A sample of the work and projects Joe Czubiak has done.`,
        },
    }

    const Hero = () => {
        return (
            <Content>
                <Row
                    className={`hero portfolio`}
                >
                    <Col xs={24} sm={24}>
                        {/* <h1>Portfolio</h1> */}
                        <Title level={1}>Portfolio</Title>
                        {/* <Title level={4}>fullstack developer • marketer • designer • indie hacker</Title> */}
                        {/* <img src={`/images/joe-czubiak-logo.svg`} alt="Joe Czubiak" /> */}
                    </Col>
                    <Col xs={24}>
                        <p>full-stack developer • indie maker</p>
                    </Col>
                </Row>
                <Row
                >
                    <Col xs={24} sm={24} style={{ overflow: `hidden` }}>
                        <Parallax
                            animation={{ x: `-10%`, playScale: [0, 1.3] }}
                            style={{ transform: `translateX(-40%)`, zIndex: 1, opacity: 1 }}
                            className=""
                        >
                            {/* <img className={`hero-wave`} src={`/images/wave.svg`} alt="" /> */}
                            <Wave className={`hero-wave`} />
                        </Parallax>
                    </Col>
                </Row>
            </Content>
        )
    }

    return (
        <>
            <MetaData location={location} />
            <MainLayout isHome={false} hero={<Hero />} className="portfolio">

                <Row justify="space-around" align="center">
                    <Col style={{ textAlign: `center` }}>
                        <br />
                        {/* <Text>I pick up the skills I need to get a project done.<br /> These are some of the projects I&apos;ve worked on.</Text> */}
                        {/* <br /> */}
                        {/* <br /> */}
                        <Paragraph>This is a sampling of the work I've done.</Paragraph>

                        <Paragraph>Please <Link href="/contact">contact me</Link> if you'd like to work together.</Paragraph>
                    </Col>
                </Row>

                {/* <Row justify="space-around">
                    <Col>
                        <Tag key="web" color="blue">Web</Tag>
                        <Tag key="Laravel" color="blue">Laravel</Tag>
                        <Tag key="Vue" color="blue">Vue</Tag>
                        <Tag key="React" color="blue">React</Tag>
                        <Tag key="Bootstrap" color="blue">Bootstrap</Tag>
                        <Tag key="Ant.Design" color="blue">Ant.Design</Tag>
                        <Tag key="Gatsby.js" color="blue">Gatsby.js</Tag>
                    </Col>
                </Row> */}

                <Divider type="horizontal" />

                <Row
                    gutter={[16, 24]}
                    type="flex"
                    justify={`space-around`}
                >
                    <Col
                        xs={24}
                        sm={22}
                        md={22}
                        lg={18}
                        xl={16}
                    >
                        <Row
                            gutter={[16, 24]}
                            type="flex"
                            justify={`space-around`}
                        >
                            {posts.map((post, i) => (
                                <Col
                                    key={i}
                                    xs={20}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                >
                                    <PortfolioCard key={i} post={post} />
                                </Col>
                            ))}
                        </Row>
                    </Col>

                </Row>
                <PaginationHelper pageContext={pageContext} />
            </MainLayout>
        </>
    )
}

Portfolio.propTypes = {
    data: PropTypes.shape({
        portfolio: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Portfolio

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
query AllPortfolioPostQuery($limit: Int!, $skip: Int!) {
    portfolio: allMarkdownRemark(
        limit: $limit,
        skip: $skip,
        filter: {frontmatter: {collectionType: {eq: "portfolio"}, public: {eq: true} }},
        sort: { fields: [frontmatter___published_at], order: DESC }
    ) {
        nodes {
            ...MultiplePortfolioFields
        }
    }
}
`
